import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth/reducer';
import app from './app/reducer';
import booking from './booking/reducer';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: {
      ...auth,
      namespaced: true,
    },
    app: {
      ...app,
      namespaced: true,
    },
    booking: {
      ...booking,
      namespaced: true,
    },
  },
});
