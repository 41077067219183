<template>
  <div id="app" :class="{ 'h-full': $route.name === '404' }">
    <component :is="layout">
      <router-view />
    </component>
    <div v-html="scripts"></div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import DefaultLayout from '@/components/layouts/default/index.vue';

import './assets/js/activeCampaign';

export default Vue.extend({
  components: {
    default: DefaultLayout
  },
  computed: {
    layout(): string {
      return 'default';
    }
  },
  data() {
    return {
      scripts: ''
    };
  }
});
</script>
