import Vue from 'vue';

import { mapGetters } from 'vuex';
import Navbar from '@/components/organisms/navbar/index.vue';
import Countdown from '@/components/atoms/countdown/index.vue';

const component = Vue.extend({
  components: {
    Navbar,
    Countdown,
  },
  data() {
    return {
      isPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'app/GET_MODAL_LOADING',
      loadingPayment: 'app/GET_MODAL_LOADING_PAYMENT',
    }),
    userSessionExpiry() {
      return this.$store.state.booking.expiryTime;
    },
  },
});

export default component;
