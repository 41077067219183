<template>
  <div class="fixed z-10 w-full top-0" v-show="userSessionExpiry !== ''">
    <div
      class="bg-gray-900 text-white px-8 py-4 text-lg text-center"
      v-if="userSessionExpiry"
    >
      <countdown :end-time="userSessionExpiry" @finish="onFinish">
        <template v-slot:process="{ timeObj }">
          <div>
            Time remaining to complete your booking
            <span class="font-semibold">{{
              `${timeObj.m} mins ${timeObj.s} seconds`
            }}</span>
          </div>
        </template>
        <template v-slot:finish>
          <span>
            Your session has timeout. You have 10 minutes to complete a booking.
            Please try again.
          </span>
        </template>
      </countdown>
    </div>
    <t-modal v-model="showModal" :clickToClose="false" :escToClose="false">
      <div class="text-center py-10">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fal"
          data-icon="exclamation-circle"
          class="svg-inline--fa fa-exclamation-circle fa-w-16 text-red-700 text-6xl w-14 h-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z"
          ></path>
        </svg>

        <p class="font-semibold text-xl mt-10">
          Oops! Your sesion has timed out.
        </p>
        <p>
          Your have 10 minutes to complete your booking. <br />
          Please try again
        </p>
      </div>
      <template v-slot:footer>
        <div class="flex justify-between">
          <button type="button" @click="closeWindow">Close</button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // userSessionExpiry: null,
      showModal: false,
    };
  },
  // mounted() {},
  computed: {
    userSessionExpiry() {
      return this.$store.state.booking.expiryTime;
    },
  },

  methods: {
    onFinish() {
      this.showModal = true;
    },
    closeWindow() {
      window.close();
    },
  },
};
</script>
