// import actions from './actions';
// import mutations from './mutations';

export default {
  state: {
    loginLoading: false,
    userData: {},
  },
  // mutations,
  // actions
};
