/* eslint-disable */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// import Home from '@/pages/home';

Vue.use(VueRouter);

export const routerName = {
  booking: 'booking',
  payment: 'payment',
  confirm: 'confirm',
  thankYou: 'thank-you',
  editBooking: 'edit-booking'
};

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: routerName.booking,
    meta: {
      layout: 'default',
      gtm: 'BookingDetails',
      gtmAdditionalEventData: { routeCategory: 'BookingDetails' }
    },
    component: () => import('@/components/pages/booking/index.vue')
  },
  {
    path: '/payment/:id',
    name: routerName.payment,
    meta: {
      layout: 'default',
      gtm: 'PaymentDetails',
      gtmAdditionalEventData: { routeCategory: 'PaymentDetails' }
    },
    component: () => import('@/components/pages/payment/index.vue')
  },
  {
    path: '/booking/:id',
    name: routerName.editBooking,
    meta: {
      layout: 'default',
      gtm: 'EditBooking',
      gtmAdditionalEventData: { routeCategory: 'EditBooking' }
    },
    component: () => import('@/components/pages/edit-booking/index.vue')
  },
  {
    path: '/confirm/:id',
    name: routerName.confirm,
    meta: {
      layout: 'default',
      gtm: 'BookingConfimation',
      gtmAdditionalEventData: { routeCategory: 'BookingConfimation' }
    },
    component: () => import('@/components/pages/confirm/index.vue')
  },
  {
    path: '/thank-you/:id/:email',
    name: routerName.thankYou,
    meta: {
      layout: 'default',
      gtm: 'BookingConfimation',
      gtmAdditionalEventData: { routeCategory: 'BookingConfimation' }
    },
    component: () => import('@/components/pages/thank-you/index.vue')
  },
  {
    path: '*',
    beforeEnter: (_to, _from, next) => {
      next('/404');
    }
  },
  {
    path: '/404',
    name: '404',
    meta: {
      gtm: 'BookingNotFound',
      gtmAdditionalEventData: { routeCategory: 'BookingNotFound' }
    },
    component: () => import('@/components/pages/not-found/index.vue')
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (_to, _from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
});

export default router;
