// import { setTokenCookie, setRefreshToken, getUserToken } from '@/utils/cookies'
// const jwtDecode = require('jwt-decode');

// export default {
//   POST_LOGIN(state) {
//     state.loginLoading = true;
//   },
//   LOGIN_DONE(state) {
//     state.loginLoading = false;
//   },
//   LOGIN_SUCCESS(state, payload) {
//     const { token, refresh_token } = payload;
//     setTokenCookie(token);
//     setRefreshToken(refresh_token);
//     const user = jwtDecode(token);
//     state.userData = user;
//   },
//   MAP_USER_TO_STATE(state, userData) {
//     state.userData = userData;
//   }
// }
export default {
  properties(state, res) {
    state.properties = res;
  },
  reservation(state, res) {
    state.reservation = res;
  },
  userDetail(state, res) {
    state.userDetail = res;
  },
  userToken(state, res) {
    state.userToken = res;
  },
  expiryTime(state, res) {
    state.expiryTime = res;
  },
  addBooking(state, res) {
    state.addBooking = res;
  },
  userContact(state, res) {
    state.userContact = { ...state.userContact, ...res };
  },
  updateStep(state, res) {
    state.step = res;
  },
  setBookingSessions(state, res) {
    state.bookingSessions = res;
  }
};
