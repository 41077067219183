/* eslint-disable */
import axios from '@/services';

export default {
  async fetchBookingRate(_context: any, payload: any) {
    try {
      const response = await axios({
        url: 'rate/rateQuote',
        method: 'POST',
        data: payload
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async fetchCountry(_context: any) {
    try {
      const response = await axios({
        url: 'countries',
        method: 'GET'
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async fetchPropertyDetail(context: any, { propertyId, query }: any) {
    try {
      const response = await axios({
        url: `properties/${propertyId}`,
        method: 'GET',
        params: query
      });

      context.commit('properties', response.data);
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async booking(_context: any, payload: any) {
    try {
      const response = await axios({
        url: 'booking',
        method: 'POST',
        data: payload
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async fetchBookingDetail(context: any, payload: any) {
    try {
      const response = await axios({
        url: `booking/${payload.id}?bookingId=${payload.bookingId}&userIp=${payload.userIp}`,
        method: 'GET'
      });
      context.commit('properties', response.data.areaDetails);
      context.commit('reservation', response.data.reservationDetails);

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async payment(_context: any, payload: any) {
    try {
      const response = await axios({
        url: `payment/${payload.id}`,
        method: 'POST',
        data: payload.payload
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateBooking(_context: any, payload: any) {
    try {
      const response = await axios({
        url: `booking/update/${payload.id}`,
        method: 'POST',
        data: payload.payload
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async fetchUserDetail(context: any, payload: any) {
    try {
      const response = await axios({
        url: `https://ipgeolocation.abstractapi.com/v1/?api_key=d83063e9fdb14778ad50e0fcb725004a`,
        method: 'GET'
      });

      context.commit('userDetail', response);

      const tokenPayload = {
        ...payload,
        userIp: response.ip_address
      };
      const token = await axios({
        url: `token/create`,
        method: 'POST',
        data: tokenPayload
      });

      context.commit('userToken', token.data);

      return token.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async addBooking(context: any, payload: any) {
    try {
      const response = await axios({
        url: 'properties/add-booking/1',
        method: 'POST',
        data: payload
      });
      context.commit('addBooking', response.data);
      context.commit('expiryTime', response.data.expiryDate);
      context.commit('updateStep', 2);
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async addBookingStep2(_context: any, payload: any) {
    try {
      const response = await axios({
        url: 'booking',
        method: 'POST',
        data: payload
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  updateUserContact(context: any, payload: any) {
    context.commit('userContact', payload);
  }
};
