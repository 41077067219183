import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueMeta from 'vue-meta';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faCircleNotch, faReply } from '@fortawesome/free-solid-svg-icons';
// import {
//   faCcVisa,
//   faCcMastercard,
//   faCcAmex,
// } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueCardFormat from 'vue-credit-card-validation';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import VueCookies from 'vue-cookies';
import VueTailwind from 'vue-tailwind';
import { TModal } from 'vue-tailwind/dist/components';
// import VueStickyDirective from '@renatodeleao/vue-sticky-directive';
import VueGtm from '@gtm-support/vue2-gtm';

import router from '@/router';
import app from '@/app.vue';

import store from './store';

import 'normalize.css';
// import 'ant-design-vue/dist/antd.css';
import 'vue2-datepicker/index.css';
import '@/assets/styles/index.scss';
import { setUpAxios } from './services';

// Vue.use(VueStickyDirective);

const settings = {
  't-modal': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay:
          'z-40  overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50',
        wrapper: 'relative mx-auto z-50 max-w-lg px-3 py-12',
        modal: 'overflow-visible relative  rounded',
        body: 'p-3',
        header: 'border-b p-3 rounded-t',
        footer: ' p-3 rounded-b',
        close:
          'flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
      },
      classes: {
        overlay: 'bg-gray-900',
        wrapper: '',
        modal: 'bg-white shadow',
        body: 'p-3',
        header: 'border-gray-100',
        footer: 'bg-gray-100',
        close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
        closeIcon: 'fill-current h-4 w-4',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: '',
      },
      variants: {
        danger: {
          overlay: 'bg-red-100',
          header: 'border-red-50 text-red-700',
          close:
            'bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border',
          modal: 'bg-white border border-red-100 shadow-lg',
          footer: 'bg-red-50',
        },
      },
    },
  },
};

// library.add(
//   faTimes,
//   faUser,
//   faBed,
//   faBath,
//   faCircle,
//   faLock,
//   faPhoneAlt,
//   faCcVisa,
//   faCcMastercard,
//   faCcAmex,
//   faCircleNotch,
//   faReply,
// );

setUpAxios();

Vue.use(Vuelidate);
Vue.use(VueMeta);
Vue.use(VueCardFormat);
Vue.use(vueAwesomeCountdown);
Vue.use(VueCookies);
Vue.use(VueTailwind, settings);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(VueGtm, {
  id: 'GTM-W8KHQ3B', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  queryParams: {
    // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    // gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    // gtm_preview: 'env-4',
    // gtm_cookies_win: 'x'
  },
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  nonce: '2726c7f26c', // Will add `nonce` to the script tag
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

new Vue({
  router,
  store,
  render: (h) => h(app),
}).$mount('#app');
