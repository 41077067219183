// import { setTokenCookie, setRefreshToken, getUserToken } from '@/utils/cookies'
// const jwtDecode = require('jwt-decode');

export default {
  MAP_MODAL_LOADING(state: any, value: any) {
    state.loading.modal = value;
  },
  MAP_MODAL_LOADING_PAYMENT(state: any, value: any) {
    state.loading.modalPayment = value;
  },
  SET_USER_IP(state, res) {
    state.userIp = res;
  },
};
