import Vue from 'vue';

const component = Vue.extend({
  data() {
    return {
      isMobileContactOpen: false,
      isMobileMenuOpen: false,
    };
  },
  methods: {
    onOpenMobileContact(event: any) {
      event.stopPropagation();
      this.isMobileContactOpen = !this.isMobileContactOpen;
    },
    onOpenMobileMenu(event: any) {
      event.stopPropagation();
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
  },
});

export default component;
