// import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
  state: {
    loading: {
      modal: false,
      modalPayment: false,
    },
    userIp: '',
  },
  mutations,
  getters,
  // actions
};
