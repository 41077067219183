import actions from './actions';
import mutations from './mutations';

export default {
  state: {
    loginLoading: false,
    userData: {},
    properties: {},
    reservation: {},
    userDetail: {},
    userToken: {},
    expiryTime: '',
    userContact: {},
    addBooking: {},
    step: 1,
    bookingSessions: {}
  },
  mutations,
  actions
};
